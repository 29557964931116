<template src="./template.html"></template>

<script>
export default {
  name: "MainSnackBar",
  methods: {
    Close() {
      this.$store.commit("SetSnackBar", { status: false, content: "" })
    }
  }
}
</script>