<template>
  <v-app id="app">
    <MainLoading />
    <MainSnackBar />
    <MainDialog />
    <v-navigation-drawer
      color="blue-grey darken-2"
      id="MainLeftDrawer"
      v-model="drawer"
      app
      v-if="$route.name != '登入'"
    >
      <v-sheet color="blue-grey darken-2" class="pa-4 d-flex align-center">
        <v-avatar class="mb-0" color="white darken-1" size="44">
          <span class="pink--text text--darken-4 headline font-weight-black">{{
            account().name[0]
          }}</span>
        </v-avatar>

        <p class="text-caption mb-0 ml-4 white--text">
          {{ account().name }}・{{ $store.state.user_type[account().role] }}
        </p>
      </v-sheet>
      <MainLeftDrawer />
    </v-navigation-drawer>

    <v-app-bar app color="white" v-if="$route.name != '登入'">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title class="font-weight-black">{{
        $route.name
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn class="blue-grey--text text--darken-2" text @click="LogOut"
        >登出</v-btn
      >
    </v-app-bar>

    <v-main class="grey lighten-4">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<style lang="scss" src="@/assets/css/app.scss"></style>

<script>
import MainLeftDrawer from "./components/MainLeftDrawer/index"
import MainSnackBar from "./components/MainSnackBar/index"
import MainDialog from "./components/MainDialog/index"
import MainLoading from "./components/MainLoading/index"

export default {
  name: "app",
  components: {
    MainSnackBar,
    MainLeftDrawer,
    MainDialog,
    MainLoading
  },
  data() {
    return {
      drawer: true
    }
  },
  methods: {
    LogOut() {
      this.$cookie.delete('account');
      this.$cookie.delete('user_token');
      this.$router.push("/login")
      this.$store.commit("SetSnackBar", { status: true, content: "您已成功登出" })
    },
    account() {
      if (this.$cookie.get("account") == null) {
        this.$store.commit("SetSnackBar", {
          status: true,
          content: "帳號驗證錯誤，請重新登入"
        })
        this.$cookie.delete('account');
        this.$cookie.delete('user_token');
        this.$router.push("/login")
        return { name: "", role: "" }
      }
      else {
        return JSON.parse(this.$cookie.get("account"))
      }
    }
  },
  created() {
    if (this.$cookie.get("account") == null || this.$cookie.get("user_token") == null && this.$route.name != "登入") {
      this.$router.push("/login")
    }
  },
  filters: {
  }
}
</script>
