export default {
    methods: {
        async GetData(path) {
            let auth_token = this.$cookie.get('user_token') == null ? "" : this.$cookie.get('user_token')
            return new Promise((resolve) => {
                this.axios({
                    method: 'get',
                    url: process.env.VUE_APP_ADMIN_API + path,
                    headers: {
                        Authorization: `Bearer ${auth_token}`
                    },
                }).then((response) => {
                    if (response.data.success == false && response.data.code == 401) {
                        resolve('error token')
                        this.LogOut("error")
                        this.$store.commit("SetSnackBar", {
                            status: true,
                            content: "帳號驗證錯誤，請重新登入"
                        })
                        //錯誤
                    } else if (response.data.code == 200) {
                        resolve(response.data.Data)
                        //success
                    } else {
                        this.$store.commit("SetDialog", {
                            status: true,
                            content: response.data.msg
                        })
                        resolve(response.data.Data)
                    }
                })
            })
        },
        SendData(path, action = "post", data) {
            let auth_token = this.$cookie.get('user_token') == null ? "" : this.$cookie.get('user_token')
            return new Promise((resolve) => {
                this.axios({
                    method: action,
                    url: process.env.VUE_APP_ADMIN_API + path,
                    data: data,
                    headers: {
                        Authorization: `Bearer ${auth_token}`
                    },
                }).then((response) => {
                    if (response.data.success == false && response.data.code == 401) {
                        resolve('error token')
                        this.LogOut("error")
                        this.$store.commit("SetDialog", {
                            status: true,
                            content: "帳號驗證錯誤，請重新登入"
                        })
                        //錯誤
                    } else if (response.data.code == 200) {
                        resolve(response.data.Data)
                        //success
                    } else {
                        this.$store.commit("SetDialog", {
                            status: true,
                            content: response.data.msg
                        })
                        resolve("error")
                    }
                })
            })
        },
        LogOut(type) {
            this.$cookie.delete('user_token')
            this.$router.push("/login")
            if (type != "error") {
                this.$store.commit("SetSnackBar", {
                    status: true,
                    content: "你已登出"
                })
            }
        }
    }
}