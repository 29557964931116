import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    main_snackbar: {
      status: false,
      content: ""
    },
    main_dialog: {
      status: false,
      content: ""
    },
    main_loading: false,
    image_url: process.env.VUE_APP_IMAGEURL,
    project_type: {
      "Instagram": [
        "限時動態",
        "貼文流量與互動",
        "私訊量",
        "導入其他網站的轉換率",
        "整體粉專讚數",
        "其他"
      ],
      "Facebook": [
        "限時動態",
        "貼文流量與互動",
        "私訊量",
        "導入其他網站的轉換率",
        "整體粉專讚數",
        "其他"
      ],
      "Google": [
        "關鍵字",
        "多媒體",
        "影片",
        "購物",
        "區域",
        "探索"
      ],
      "蝦皮": [
        "貼文流量與互動",
        "私訊量",
        "其他"
      ],
      "LINE@": [
        "貼文流量與互動",
        "私訊量",
        "其他"
      ],
    },
    project_type_color: {
      "Instagram": "pink--text text--lighten-1",
      "Facebook": "light-blue--text text--darken-1",
      "Google": "red--text text--darken-3",
      "蝦皮": "orange--text text--darken-3",
      "LINE@": "green--text text--darken-3"
    },
    user_type: {
      A: "管理員",
      E: "編輯者",
      C: "品牌顧問",
      V: "檢視者"
    }
  },
  mutations: {
    SetSnackBar(state, item) {
      state.main_snackbar.status = item.status
      state.main_snackbar.content = item.content
    },
    SetDialog(state, item) {
      state.main_dialog.status = item.status
      state.main_dialog.content = item.content
    },
    SetLoading(state, action) {
      state.main_loading = action
    }
  },
  actions: {},
  modules: {}
})