import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

// 解决报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

const routes = [{
    path: '/login',
    name: '登入',
    component: () => import( /* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/',
    name: '首頁',
    component: Home
  },
  {
    path: '/customers',
    name: '客戶管理',
    component: () => import( /* webpackChunkName: "customers" */ '../views/Customers/index.vue')
  },
  {
    path: '/ad_type',
    name: '廣告類型管理',
    component: () => import( /* webpackChunkName: "ad_type" */ '../views/AdType/index.vue')
  },
  {
    path: '/ad_campaign',
    name: '廣告案件管理',
    component: () => import( /* webpackChunkName: "ad_campaign" */ '../views/Projects/index.vue')
  },
  {
    path: '/ad_effect',
    name: '廣告成效',
    component: () => import( /* webpackChunkName: "ad_effect" */ '../views/Effect/index.vue')
  },
  {
    path: '/users',
    name: '使用者管理',
    component: () => import( /* webpackChunkName: "users" */ '../views/Users/index.vue')
  },
  {
    path: '/billing',
    name: '帳務管理',
    component: () => import( /* webpackChunkName: "billing" */ '../views/Billing/index.vue')
  },
  {
    path: '/customer_auth',
    name: '客戶權限管理',
    component: () => import( /* webpackChunkName: "customer_auth" */ '../views/CustomerAuth/index.vue')
  },


]

const router = new VueRouter({
  routes,
  mode: "history",
  base: "/",
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  }
})

export default router