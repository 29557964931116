<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/Logo.svg')"
          class="my-3"
          contain
          height="80"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          歡迎使用泳欣廣告後台系統
        </h1>

        <p class="subheading font-weight-regular">
          請點選左邊選單進行操作,若您遇到任何問題請先聯絡系統管理員
          <br />需要技術障礙排除或網站功能出錯
          <a href="https://www.yongxin-design.com/Contact" target="_blank"
            >請聯絡我們</a
          >
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HelloWorld',
}
</script>
