<template src="./template.html"></template>

<script>
export default {
  name: "left_menu",
  data() {
    return {
      drawer: null,
      PageMenu: null,
    }
  },
  methods: {
    account() {
      if (this.$cookie.get("account") == null) {
        this.$store.commit("SetSnackBar", {
          status: true,
          content: "帳號驗證錯誤，請重新登入"
        })
        this.$cookie.delete('account');
        this.$cookie.delete('user_token');
        this.$router.push("/login")
        return { name: "", role: "" }
      }
      else {
        return JSON.parse(this.$cookie.get("account"))
      }
    },
    Init() {
      let menu = [
        {
          "id": "0",
          "icon": "mdi-home-outline",
          "text": "首頁",
          "link": "/"
        },
        {
          "id": "1",
          "icon": "mdi-face-agent",
          "text": "客戶管理",
          "link": "/customers"
        },
        {
          "id": "2",
          "icon": "mdi-card-bulleted-outline",
          "text": "廣告案件",
          "link": "/ad_campaign"
        },
        {
          "id": "3",
          "icon": "mdi-chart-line",
          "text": "廣告成效",
          "link": "/ad_effect"
        },
      ]
      let admin_menu = [
        {
          "id": "1",
          "icon": "mdi-account-key",
          "text": "客戶權限管理",
          "link": "/customer_auth"
        },
        {
          "id": "4",
          "icon": "mdi-text-box-check",
          "text": "帳務查詢",
          "link": "/billing"
        },
        {
          "id": "6",
          "icon": "mdi-account-circle",
          "text": "使用者管理",
          "link": "/users"
        },
      ]
      this.account().role == "A" ? this.PageMenu = menu.concat(admin_menu) : this.PageMenu = menu
    }
  },
  mounted() {
    this.Init()
  },
}
</script>