export default {
    methods: {
        GetArrayBuffer(fileObj) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.addEventListener('load', () => {
                    resolve(reader.result)
                })
                reader.addEventListener('error', () => {
                    reject('error occurred in getArrayBuffer')
                })
                reader.readAsDataURL(fileObj)
            })
        },
    }
}